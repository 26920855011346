<ion-header>
    <h1 class="title" [innerHTML]="title"></h1>
    <div class="etiquette__popup__close" *ngIf="user && user.agree_etiquette !== null">
        <ion-button (click)="close()" fill="clear">
            <ion-icon src="/assets/svgs/close.svg" slot="icon-only"></ion-icon>
        </ion-button>
    </div>
</ion-header>
<div class="etiquette__popup__content ion-padding-start ion-padding-end" [innerHTML]="text"></div>
<ion-footer>
    <form class="form ion-padding" [formGroup]="form" (submit)="submitForm()" novalidate>
        <ion-radio-group formControlName="radio">
            <ion-item class="etiquette__popup__radio">
                <ion-label>{{ agree }}</ion-label>
                <ion-radio mode="md" slot="start" [value]="1"></ion-radio>
            </ion-item>
            <ion-item class="etiquette__popup__radio">
                <ion-label>{{ disagree }}</ion-label>
                <ion-radio mode="md" slot="start" [value]="0"></ion-radio>
            </ion-item>
        </ion-radio-group>
        <ion-button class="etiquette__popup__button" fill="outline" mode="ios" color="secondary" type="submit">
            <ng-container *ngIf="! loading">{{ submit }}</ng-container>
            <ion-spinner *ngIf="loading" color="secondary"></ion-spinner>
        </ion-button>
    </form>
</ion-footer>