import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../services/auth.service";
import { LanguageService } from "../services/language.service";

@Injectable({
    providedIn: 'root'
})
export class NolanguageGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private languageService: LanguageService,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.languageService.loaded.value) {
            const language = this.languageService.getStoredLanguage().value;
            if (! language) {
                return this.router.createUrlTree(['language']);
            }
        }

        return true;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.languageService.loaded.value) {
            const language = this.languageService.getLanguage().value;
            if (! language) {
                return this.router.createUrlTree(['language']);
            }
        }

        return true;
    }
}
