import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from "@ionic/angular";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-popmenu',
    templateUrl: './popmenu.component.html',
    styleUrls: ['./popmenu.component.scss'],
})
export class PopmenuComponent implements OnInit {
    @Input() menu = [];
    closeText = null;

    constructor(
        private translate: TranslateService,
        private popoverController: PopoverController,
        private navController: NavController
    ) { }

    ngOnInit() {
        this.translate.get('popmenu.close.text').subscribe(value => {
            this.closeText = value;
        });
    }

    close() {
        this.popoverController.dismiss();
    }

    click(item) {
        if (item.callback) {
            item.callback();
        }
        if (item.link) {
            this.navController.navigateForward(item.link);
        }
        this.close();
    }
}
