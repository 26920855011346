import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NoauthGuard } from './guards/noauth.guard';
import { WaitGuard } from "./guards/wait.guard";
import { NotconfirmedGuard } from "./guards/notconfirmed.guard";
import { ConfirmedGuard } from "./guards/confirmed.guard";
import { NolanguageGuard } from "./guards/nolanguage.guard";

const routes: Routes = [
    {
        path: '',
        canActivate: [WaitGuard],
        children: [
            { path: 'language', canActivate: [WaitGuard], loadChildren: () => import('./pages/language/language.module').then(m => m.LanguagePageModule) },
            { path: 'check', canActivate: [WaitGuard, NolanguageGuard, NoauthGuard], loadChildren: () => import('./pages/check/check.module').then(m => m.CheckPageModule) },
            { path: 'register', canActivate: [WaitGuard, NolanguageGuard, NoauthGuard], loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
            { path: 'register', canActivate: [WaitGuard, NolanguageGuard, NoauthGuard], loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
            { path: 'register/:miscode/:code', canActivate: [WaitGuard], loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
            { path: 'login', canActivate: [WaitGuard, NolanguageGuard, NoauthGuard], loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
            { path: 'intro', canActivate: [WaitGuard, NolanguageGuard, AuthGuard], loadChildren: () => import('./pages/intro/intro.module').then(m => m.IntroPageModule) },
            { path: 'confirm', canActivate: [WaitGuard, NolanguageGuard, AuthGuard, ConfirmedGuard], loadChildren: () => import('./pages/confirm/confirm.module').then(m => m.ConfirmPageModule) },
            { path: 'confirm/:token', canActivate: [WaitGuard, NolanguageGuard, AuthGuard, ConfirmedGuard], loadChildren: () => import('./pages/confirm/confirm.module').then(m => m.ConfirmPageModule) },
            { path: 'forgotpass', canActivate: [WaitGuard, NolanguageGuard, NoauthGuard], loadChildren: () => import('./pages/forgotpass/forgotpass.module').then(m => m.ForgotpassPageModule) },
            { path: 'resetpass/:token', canActivate: [WaitGuard, NolanguageGuard, NoauthGuard], loadChildren: () => import('./pages/resetpass/resetpass.module').then(m => m.ResetpassPageModule) },
            { path: 'assumeuser/:token', canActivate: [WaitGuard], loadChildren: () => import('./pages/assumeuser/assumeuser.module').then(m => m.AssumeuserPageModule) },
            { path: 'members', canActivate: [WaitGuard, NolanguageGuard], loadChildren: () => import('./pages/members/members.module').then( m => m.MembersPageModule) },
            { path: 'error', canActivate: [], loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorPageModule) },
            { path: '', canActivate: [WaitGuard, NolanguageGuard, NotconfirmedGuard], redirectTo: '/members/modules', pathMatch: 'full' },
        ]
    },
];



@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
