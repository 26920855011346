import { Component, NgZone } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { DataService } from './services/data.service';
import { LanguageService } from './services/language.service';
import { AuthService } from './services/auth.service';
import { UpdateService } from "./services/update.service";
import {SwUpdate} from '@angular/service-worker';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    timerUpdates = null;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private dataService: DataService,
        private languageService: LanguageService,
        private updateService: UpdateService,
        private authService: AuthService,
        private navController: NavController,
        private router: Router,
        private swUpdate: SwUpdate
    ) {
        this.initializeApp();
    }

    async initializeApp() {
        clearInterval(this.timerUpdates);
        this.timerUpdates = setInterval(async () => {
            console.log('Checking for updates');
            this.updateService.update();
        }, 300000);

        if (this.swUpdate.available) {
            this.swUpdate.available.subscribe(async (event) => {
                console.log('Available updates');
                await this.swUpdate.activateUpdate();
            });
        }

        if (this.swUpdate.activated) {
            this.swUpdate.activated.subscribe(event => {
                console.log('Activating updates');
                window.location.reload(true);
            });
        }

        console.log('TODO: Splash screen');
        await this.dataService.load();
        await this.languageService.init();

        if (this.authService.isAuthenticated()) {
            await this.authService.check().subscribe();
        }
        await this.updateService.update();
        await this.platform.ready();

        console.log('Ready');

        if (this.router.url === '' || this.router.url === '/') {
            this.navController.navigateRoot(['members', 'modules']);
        }
        //             if (state === null) {
        //                 return;
        //             }
        //             this.auth = this.authService.getAuth();
        //
        //             const language = this.dataService.getLanguage();
        //             if (! language) {
        //                 this.router.navigate(['language']);
        //                 setTimeout(() => {
        //                     this.loading = false;
        //                     if (this.platform.is('cordova')) {
        //                         this.splashScreen.hide();
        //                     }
        //                 }, 200);
        //                 return;
        //             }
        //
        //             if (! this.authService.isAuthenticated()) {
        //                 const hasEndpoints = ['/check', '/register', '/login', '/forgotpass', '/resetpass/', '/confirm/'].filter(uri => {
        //                     return this.previousUri && this.previousUri.startsWith(uri)
        //                 });
        //                 if (! this.previousUri || !hasEndpoints.length) {
        //                     this.router.navigate(['check']);
        //                 }
        //                 setTimeout(() => {
        //                     this.loading = false;
        //                     if (this.platform.is('cordova')) {
        //                         this.splashScreen.hide();
        //                     }
        //                 }, 200);
        //                 return;
        //             }
        //
        //             this.dataService.setAuthName(this.auth.user.name);
        //             this.dataService.setAuthEmail(this.auth.user.email);
        //
        //             if (this.platform.is('cordova')) {
        //                 this.firebaseService.init();
        //             }
        //
        //             if (! this.redirect) {
        //                 setTimeout(() => {
        //                     this.loading = false;
        //                     if (this.platform.is('cordova')) {
        //                         this.splashScreen.hide();
        //                     }
        //                 }, 200);
        //                 return;
        //             }
        //             this.redirect = false;
        //
        //             const hasEndpoints = ['/check', '/register', '/login', '/forgotpass', '/resetpass/', '/confirm/'].filter(uri => {
        //                 return this.previousUri && this.previousUri.startsWith(uri)
        //             });
        //             if (this.previousUri && !hasEndpoints.length) {
        //                 this.router.navigate([this.previousUri]);
        //                 setTimeout(() => {
        //                     this.loading = false;
        //                     if (this.platform.is('cordova')) {
        //                         this.splashScreen.hide();
        //                     }
        //                 }, 200);
        //                 return;
        //             }
        //
        //             if (! this.dataService.getIntro()) {
        //                 this.router.navigate(['setup', 'intro']);
        //                 this.loading = false;
        //                 if (this.platform.is('cordova')) {
        //                     this.splashScreen.hide();
        //                 }
        //                 return;
        //             }
        //
        //             this.router.navigate(['members']);
        //             setTimeout(() => {
        //                 this.loading = false;
        //                 if (this.platform.is('cordova')) {
        //                     this.splashScreen.hide();
        //                 }
        //             }, 200);
        //         });
        //
        //         this.authService.init();
        //     });
        //
        //     this.languageService.init();
        // });
    }
}
