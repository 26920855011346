import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { AuthService } from "../services/auth.service";
import { Observable } from "rxjs";
import { LanguageService } from "../services/language.service";

@Injectable({
    providedIn: 'root'
})
export class NoauthGuard implements CanActivate, CanActivateChild {

    constructor(
        private authService: AuthService,
        private languageService: LanguageService,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.authService.isAuthenticated()) {
            if (state.url.indexOf('/reset') !== -1) {
                return this.router.createUrlTree(['members', 'change-password']);
            }

            return this.router.createUrlTree(['members', 'modules']);
        }

        return true;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.authService.isAuthenticated()) {
            if (state.url.indexOf('/reset') !== -1) {
                return this.router.createUrlTree(['members', 'change-password']);
            }

            return this.router.createUrlTree(['members', 'modules']);
        }

        return true;
    }
}
