import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { PopoverController } from "@ionic/angular";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";

@Component({
    selector: 'app-etiquette',
    templateUrl: './etiquette.component.html',
})
export class EtiquetteComponent implements OnInit {
    form: FormGroup;
    isSubmitted: boolean = false;
    loading: boolean = false;
    user: any = {};

    title: string;
    text: string;
    agree: string;
    disagree: string;
    submit: string;

    constructor(
        private translate: TranslateService,
        private popoverController: PopoverController,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.user = this.authService.getUser();

        this.form = this.formBuilder.group({
            radio: [this.user && this.user.agree_etiquette, [Validators.required]],
        });

        this.translate.get(['']).subscribe(() => {
            this.reloadTrans();
        });
        this.translate.onDefaultLangChange.subscribe(() => {
            this.reloadTrans();
        });
        this.translate.onLangChange.subscribe(() => {
            this.reloadTrans();
        });
    }

    reloadTrans() {
        this.title = this.translate.instant('etiquette.header.title');
        this.text = this.translate.instant('etiquette.content.text');
        this.agree = this.translate.instant('etiquette.radio.agree');
        this.disagree = this.translate.instant('etiquette.radio.disagree');
        this.submit = this.translate.instant('etiquette.submit.text');
    }

    submitForm() {
        this.isSubmitted = true;

        if (! this.form.valid) {
            return;
        }

        this.loading = true;

        this.apiService.markEtiquette(this.form.get('radio').value)
            .subscribe(
                async res => {
                    this.loading = false;

                    const user = await this.authService.processMe(res);

                    this.close();
                },
                err => {
                    this.loading = false;
                }
            );
    }

    async close() {
        await this.popoverController.dismiss();
    }
}
