import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DataService {
    loaded = new BehaviorSubject(null);
    data: {[key: string]: any} = {};

    constructor(
        private storage: Storage,
    ) { }

    async load() {
        console.log('Loading Data');

        const keys = await this.storage.keys();
        let promises = [];
        if (keys) {
            for (let i in keys) {
                let key = keys[i];
                promises.push(new Promise(async resolve => {
                    await this.set(key, await this.storage.get(key));
                    console.log('- ' + key);
                    resolve();
                }));
            }
        }
        await Promise.all(promises);

        this.loaded.next(true);
    }

    all() {
        return this.data;
    }

    get(key, def = null) {
        if (! this.data[key]) {
            this.data[key] = new BehaviorSubject(def);
        }

        return this.data[key];
    }

    set(key, value) {
        return new Promise(async resolve => {
            if (value === null) {
                await this.storage.remove(key);
                if (this.data[key]) {
                    this.data[key].next(null);
                }
            } else {
                await this.storage.set(key, value);

                if (! this.data[key]) {
                    this.data[key] = new BehaviorSubject(value);
                }
                this.data[key].next(value);
            }
            resolve();
        });
    }

    clearSession() {
        return new Promise(async resolve => {
            const promises = [];

            console.log('Remove session data');

            promises.push(this.set('auth/loggedin', false));
            promises.push(this.set('auth/flow', null));
            promises.push(this.set('auth/user', null));
            promises.push(this.set('auth/learningtype', null));
            promises.push(this.set('auth/date', null));

            promises.push(this.set('messages', null));

            await Promise.all(promises);

            await this.clearCache();

            resolve();
        });
    }

    clearCache() {
        return new Promise(async resolve => {
            const promises = [];

            const keys = await this.storage.keys();
            for (const i in keys) {
                const key = keys[i];
                if (
                    key.substr(0, 11) === 'updated_at/'
                    && ['languages'].indexOf(key.substr(key.lastIndexOf('/') + 1)) === -1
                ) {
                    console.log('- ' + key);
                    promises.push(this.set(key, null));
                    promises.push(await this.set(key.substr(11), null));
                }
            }

            await Promise.all(promises);

            resolve();
        });
    }
}
