<ion-header>
    <ion-img [src]="user.company_logo" [alt]="user.company_name" class="certificate__popup__image"></ion-img>
    <h1 class="title" [innerHTML]="title"></h1>
    <p class="subtitle" [innerHTML]="subtitle"></p>
    <div class="certificate__popup__close">
        <ion-button (click)="close()" fill="clear">
            <ion-icon src="/assets/svgs/close.svg" slot="icon-only"></ion-icon>
        </ion-button>
    </div>
</ion-header>
<div class="certificate__popup__content ion-padding-start ion-padding-end" [innerHTML]="text"></div>
<ion-footer>
    <div class="form ion-padding">
        <ion-button class="certificate__popup__button" mode="ios" fill="outline" color="secondary" (click)="submitForm()">{{ submit }}</ion-button>
    </div>
</ion-footer>
