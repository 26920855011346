export const environment = {
  production: true
};

export const VERSION = '3.1.12';

export const API_HOST = 'https://webapi.driveiq.com/api';

export const CDN_HOST = 'https://webapi.driveiq.com';

export const SENTRY_DSN = 'https://4991e03c2acd48299e2476ab58d05e61@sentry.io/1763072';
