import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot, UrlTree
} from '@angular/router';
import { Observable } from "rxjs";
import { LanguageService } from "../services/language.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private authService: AuthService,
        private languageService: LanguageService,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (! this.authService.isAuthenticated()) {
            return this.router.createUrlTree(['check']);
        }

        return true;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (! this.authService.isAuthenticated()) {
            return this.router.createUrlTree(['check']);
        }

        return true;
    }
}
