import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { LanguageService } from "../services/language.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private languageService: LanguageService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this.authService.getToken();
        let authReq = req;

        if (authToken) {
            authReq = req.clone({
                headers: req.headers.set('Authorization', authToken)
            });
        }

        const language = this.languageService.language.value;
        if (language) {
            authReq = authReq.clone({
                headers: authReq.headers.set('Accept-Language', language),
            });
        }

        return next.handle(authReq).pipe(catchError(err => {
            if (err.status === 401) {
                this.authService.logout();
            }

            return throwError(err);
        }));
    }
}
