import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from './pipes/pipes.module';
import { IonicStorageModule } from '@ionic/storage';
import { SentryErrorHandler } from './handlers/error.handler';
import * as Sentry from 'sentry-cordova';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment, SENTRY_DSN } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from './components/components.module';
import { AuthInterceptor } from "./http-interceptors/auth.interceptor";

Sentry.init({
    dsn: SENTRY_DSN,
    beforeSend: (event, hint) => {
        const error: any = hint.originalException;
        if (error && error.status && error.status == 401) {
            return null;
        }

        if (!event.user && window && window.localStorage) {
            const email = window.localStorage.getItem('error/email') || undefined;
            if (email) {
                event.user = {email};
            }
        }

        return event;
    },
});

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        IonicModule.forRoot({
            scrollAssist: true
        }),
        AppRoutingModule,
        IonicStorageModule.forRoot({
            name: '__driveiq',
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        PipesModule,
        ComponentsModule,
        TranslateModule.forRoot({
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: environment.production ? SentryErrorHandler : ErrorHandler },
        SplashScreen,
        StatusBar,
        ScreenOrientation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
