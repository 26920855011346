import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import {API_HOST, VERSION} from '../../environments/environment';
import { take } from 'rxjs/operators';
import * as Sentry from 'sentry-cordova';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    url(uri) {
        return API_HOST + uri;
    }

    constructor(private http: HttpClient, private dataService: DataService) { }

    serialize(obj) {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    send(uri, data = null, attempt = 0) {
        const url = this.url(uri);

        let request = this.http.get<any>(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
                'X-Version': VERSION,
            },
        });

        if (data) {
            request = this.http.post<any>(url, data, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Version': VERSION,
                },
            });
        }

        return new Observable(observer => {
            request.pipe(take(1))
            .subscribe(
                data => {
                    observer.next(data);
                    observer.complete();
                }
                , async error => {
                    if (error.status || attempt > 2) {
                        observer.error(error);
                        return;
                    }

                    await this.delay(1000);
                    this.send(uri, data, attempt + 1)
                        .subscribe((data) => {
                            observer.next(data);
                            observer.complete();
                        }, error => observer.error(error));
                }
            );
        })
        .pipe(take(1));
    }

    language(language: string): Observable<any> {
        return this.send('/languages/' + language);
    }

    languages(): Observable<any> {
        return this.send('/languages');
    }

    me(): Observable<any> {
        return this.send('/me');
    }

    invite(name: string, email: string): Observable<any> {
        return this.send('/invite', {
            name,
            email,
        });
    }

    checkLogin(email: string): Observable<any> {
        return this.send('/login/check', {
            email,
        });
    }

    update(name: string, email: string, password: string = null, newPassword: string = null): Observable<any> {
        return this.send('/me', {
            name,
            email,
            password,
            newPassword,
        });
    }

    version() {
        return this.send('/version');
    }

    updates() {
        return this.send('/updates');
    }

    login(email: string, password: string): Observable<any> {
        return this.send('/login', {
            email,
            password
        });
    }

    assumeuser(token: string): Observable<any> {
        return this.send('/assumeuser', {
            token,
        });
    }

    resetpass(token: string, password: string = null): Observable<any> {
        return this.send('/resetpass/' + token, password != null ? {password} : null);
    }

    confirm(token: string): Observable<any> {
        return this.send('/confirm', {
            token,
        });
    }

    resendConfirm(): Observable<any> {
        return this.send('/resend-confirm', {sent: new Date()});
    }

    forgotpass(email: string): Observable<any> {
        return this.send('/forgotpass', {email});
    }

    logout(): Observable<any> {
        return this.send('/logout', {sent: new Date()});
    }

    register(
        code: string,
        miscode: string,
        firstname: string,
        lastname: string,
        email: string,
        password: string,
        invite: boolean = false
    ): Observable<any> {
        return this.send('/register', {
            code,
            miscode,
            firstname,
            lastname,
            email,
            password,
            invite
        });
    }

    code(
        miscode: string,
        code: string
    ): Observable<any> {
        return this.send('/code/' + miscode + '/' + code);
    }

    flow(id): Observable<any> {
        return this.send('/flow/' + id);
    }

    flowViewed(id): Observable<any> {
        return this.send('/flow/' + id, {sent: new Date()});
    }

    module(flow_id, id): Observable<any> {
        return this.send('/modules/' + flow_id + '/' + id);
    }

    quickguides(): Observable<any> {
        return this.send('/quickguides');
    }

    moduleQuestions(flow_id, id): Observable<any> {
        return this.send('/modulequestions/' + flow_id + '/' + id);
    }

    submitModuleQuestion(flow_id, module_id, id, value): Observable<any> {
        return this.send('/modulequestions/' + flow_id + '/' + module_id, {
            id,
            value
        });
    }

    completeModule(flow_id, id): Observable<any> {
        return this.send('/modulequestions/' + flow_id + '/' + id, {complete: true});
    }

    changeDetails(
        firstname: string,
        lastname: string,
        email: string
    ): Observable<any> {
        return this.send('/me', {
            firstname,
            lastname,
            email
        });
    }

    changePassword(password: string): Observable<any> {
        return this.send('/me/password', {
            password
        });
    }

    markEtiquette(agree: boolean): Observable<any> {
        return this.send('/me/etiquette', {
            agree,
        })
    }

    log(rating): Observable<any> {
        return this.send('/me/log', {
            rating
        });
    }

    messages(): Observable<any> {
        return this.send('/messages');
    }

    message(id): Observable<any> {
        return this.send('/messages/' + id);
    }

    messageClicked(id): Observable<any> {
        return this .send('/messages/' + id, {sent: new Date()});
    }

    introViewed(): Observable<any> {
        return this .send('/me/intro', {sent: new Date()});
    }
}
