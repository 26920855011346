import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { NavController, PopoverController } from "@ionic/angular";
import { AuthService } from "../../services/auth.service";

@Component({
    selector: 'app-certificate',
    templateUrl: './certificate.component.html',
})
export class CertificateComponent implements OnInit {
    title: string;
    subtitle: string;
    text: string;
    submit: string;

    user: any = {};

    constructor(
        private translate: TranslateService,
        private popoverController: PopoverController,
        private authService: AuthService,
        private navController: NavController
    ) { }

    ngOnInit() {
        this.user = this.authService.getUser();

        this.translate.get(['']).subscribe(() => {
            this.reloadTrans();
        });
        this.translate.onDefaultLangChange.subscribe(() => {
            this.reloadTrans();
        });
        this.translate.onLangChange.subscribe(() => {
            this.reloadTrans();
        });
    }

    reloadTrans() {
        this.title = this.translate.instant('certificate.header.title');
        this.subtitle = this.translate.instant('certificate.header.subtitle', {
            "name": this.user.firstname.substr(0,1).toUpperCase() + '. ' + this.user.lastname.substr(0,1).toUpperCase() + this.user.lastname.substr(1).toLowerCase()
        });
        this.text = this.translate.instant('certificate.content.text');
        this.submit = this.translate.instant('certificate.submit.text');
    }

    async submitForm() {
        this.navController.navigateForward(['members', 'profile']);
        await this.close();
    }

    async close() {
        await this.popoverController.dismiss();
    }
}
