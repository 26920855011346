import {Component, Input, OnInit} from '@angular/core';
import {NavController, PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-popmessage',
    templateUrl: './popmessage.component.html',
    styleUrls: ['./popmessage.component.scss'],
})
export class PopmessageComponent implements OnInit {
    @Input() text = '';
    closeText = null;

    constructor(
        private translate: TranslateService,
        private popoverController: PopoverController,
    ) {
    }

    ngOnInit() {
    }
}
