import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopmenuComponent } from "./popmenu/popmenu.component";
import { IonicModule } from "@ionic/angular";
import { EtiquetteComponent } from "./etiquette/etiquette.component";
import { CertificateComponent } from "./certificate/certificate.component";
import { ReactiveFormsModule } from "@angular/forms";
import {PopmessageComponent} from './popmessage/popmessage.component';

@NgModule({
    declarations: [
        PopmenuComponent,
        EtiquetteComponent,
        CertificateComponent,
        PopmessageComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule
    ],
    exports: [
        PopmenuComponent,
        EtiquetteComponent,
        CertificateComponent,
        PopmessageComponent,
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class ComponentsModule { }
