import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { DataService } from "../services/data.service";
import { takeUntil } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class WaitGuard implements CanActivate {
    unsubscriber$: Subject<void> = null;

    constructor(
        private dataService: DataService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this.unsubscriber$ = new Subject();
        return new Promise(resolve => {
            this.dataService.loaded.pipe(takeUntil(this.unsubscriber$)).subscribe(loaded => {
                if (loaded) {
                    this.unsubscriber$.next();
                    this.unsubscriber$.complete();

                    resolve(true);
                }
            });
        });
    }
}
