import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {DataService} from './data.service';
import {AuthService} from './auth.service';
import {Storage} from '@ionic/storage';
import {VERSION} from '../../environments/environment';
import {SwUpdate} from '@angular/service-worker';

@Injectable({
    providedIn: 'root'
})
export class UpdateService {
    updates: any = {};
    steps = 0;
    isUpdating: boolean;
    version: string = null;
    checkingUpdates: boolean;

    constructor(
        private apiService: ApiService,
        private dataService: DataService,
        private authService: AuthService,
        private storage: Storage,
        private swUpdate: SwUpdate,
    ) {
        this.version = VERSION;
    }

    showUpdatingScreen() {
        console.log('TODO: Loading screen');
    }

    hideUpdatingScreen() {
        console.log('TODO: Loading screen');
    }

    async forceUpdate(type) {
        if (!type) {
            return;
        }

        await this.dataService.set('updated_at/' + VERSION + '/' + type, null);
        await this.update();
    }

    async checkUpdates() {
        if (this.checkingUpdates) {
            return;
        }
        this.checkingUpdates = true;

        if (this.swUpdate.isEnabled) {
            console.log('Checking for updates');
            this.swUpdate.checkForUpdate();
        }

        const latestVersion = await new Promise(async resolve => {
            this.apiService.version().subscribe(async (res: string) => {
                console.log('Version check: ' + VERSION + ' == ' + res);

                if (VERSION && VERSION < res) {
                    if (this.swUpdate.isEnabled) {
                        console.log('Waiting for updates');

                        await new Promise(async resolve2 => {
                            setTimeout(() => {
                                console.log('Waited 30s for updates');
                                resolve2();
                            }, 30000);
                        });
                    } else {
                        window.location.reload(true);
                    }
                }

                resolve(res);
            });
        });

        this.checkingUpdates = false;
        this.version = VERSION;

        if (VERSION && VERSION < latestVersion) {
            this.checkUpdates();
        }
    }

    async update() {
        if (this.isUpdating) {
            return;
        }
        this.isUpdating = true;

        const updatedAt = this.dataService.get('updated_at/' + VERSION).value;
        if (!updatedAt) {
            this.showUpdatingScreen();
        }

        this.checkUpdates();

        await new Promise(async resolve => {
            this.apiService.updates().subscribe(res => {
                this.updates = res;

                resolve();
            });
        });

        this.steps = 0;

        const promises = [];

        for (const key in this.dataService.all()) {
            if (key.indexOf('updated_at/') === -1 || key.indexOf('updated_at/' + VERSION) !== -1) {
                continue;
            }

            this.dataService.set(key, null);
        }

        for (const update in this.updates) {
            const date: string = String((new Date(this.updates[update])).getTime());

            const arg = update.indexOf('/') !== -1 ? update.substr(update.indexOf('/') + 1) : null;
            const func = arg ? update.substr(0, update.indexOf('/')) : update;

            if (typeof this[func] === 'function' && this.dataService.get('updated_at/' + VERSION + '/' + update).value !== date) {
                const args = arg ? arg.split('/') : [];
                args.unshift(date);
                promises.push(this[func](...args));
            }
        }

        if (promises.length > 0) {
            await (new Promise(async resolve => {
                for (let i = 0; i < promises.length; i += 3) {
                    await Promise.all(promises.slice(i, 3));
                }

                resolve();
            }))
                .then(() => {
                    this.dataService.set('updated_at/' + VERSION, (new Date()).getTime());
                    this.hideUpdatingScreen();
                });
        }

        this.isUpdating = false;

        return true;
    }

    languages(date) {
        const updatedAt = this.dataService.get('updated_at/' + VERSION + '/languages').value;
        if (!updatedAt) {
            this.showUpdatingScreen();
        }

        return new Promise((resolve) => {
            this.apiService.languages()
                .subscribe(async (languages) => {
                    await this.dataService.set('languages', languages);

                    this.dataService.set('updated_at/' + VERSION + '/languages', date);

                    this.steps++;
                    resolve();
                });
        });
    }

    flow(date, id) {
        if (!this.authService.isAuthenticated()) {
            this.steps++;
            return Promise.resolve();
        }

        const updatedAt = this.dataService.get('updated_at/' + VERSION + '/flow/' + id).value;
        if (!updatedAt) {
            this.showUpdatingScreen();
        }

        return new Promise((resolve) => {
            this.apiService.flow(id)
                .subscribe(async (res) => {
                    this.dataService.set('flow/' + id, res);

                    this.dataService.set('updated_at/' + VERSION + '/flow/' + id, date);

                    this.steps++;
                    resolve();
                });
        });
    }

    modules(date, flowId, id) {
        if (!this.authService.isAuthenticated()) {
            this.steps++;
            return Promise.resolve();
        }

        const updatedAt = this.dataService.get('updated_at/' + VERSION + '/modules/' + flowId + '/' + id).value;
        if (!updatedAt) {
            this.showUpdatingScreen();
        }

        return new Promise(resolve => {
            this.apiService.module(flowId, id)
                .subscribe(async res => {
                    await this.dataService.set('modules/' + flowId + '/' + id, res);

                    this.dataService.set('updated_at/' + VERSION + '/modules/' + flowId + '/' + id, date);

                    this.steps++;
                    resolve();
                });
        });
    }

    modulequestions(date, flowId, id) {
        if (!this.authService.isAuthenticated()) {
            this.steps++;
            return Promise.resolve();
        }

        const updatedAt = this.dataService.get('updated_at/' + VERSION + '/modulequestions/' + flowId + '/' + id).value;
        if (!updatedAt) {
            this.showUpdatingScreen();
        }

        return new Promise(resolve => {
            this.apiService.moduleQuestions(flowId, id)
                .subscribe(async res => {
                    await this.dataService.set('modulequestions/' + res.flow_id + '/' + res.module_id, res.questions);

                    this.dataService.set('updated_at/' + VERSION + '/modulequestions/' + flowId + '/' + id, date);

                    this.steps++;
                    resolve();
                });
        });
    }

    quickguides(date) {
        if (!this.authService.isAuthenticated()) {
            this.steps++;
            return Promise.resolve();
        }

        const updatedAt = this.dataService.get('updated_at/' + VERSION + '/quickguides').value;
        if (!updatedAt) {
            this.showUpdatingScreen();
        }

        return new Promise(resolve => {
            this.apiService.quickguides()
                .subscribe(async res => {
                    await this.dataService.set('quickguides', res);

                    this.dataService.set('updated_at/' + VERSION + '/quickguides', date);

                    this.steps++;
                    resolve();
                });
        });
    }

    get progress() {
        return Math.round(this.steps / Object.keys(this.updates).length) * 100;
    }
}
